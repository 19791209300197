@import "bootstrap/dist/css/bootstrap.min.css";
@import "react-responsive-carousel/lib/styles/carousel.min.css";

body {
  font: normal normal 400 16px/23px Manrope;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  height: 37px !important;
  border: 1px solid #dee2e6 !important ;
}

.PhoneInputCountrySelect[disabled] {
  display: none !important;
}

.jodit-color-picker__color-item {
  display: none;
}

.jodit-color-picker__color-item[data-color="#E69138"],
.jodit-color-picker__color-item[data-color="#900095"],
.jodit-color-picker__color-item[data-color="#CC0000"],
.jodit-color-picker__color-item[data-color="#3C78D8"],
.jodit-color-picker__color-item[data-color="#E23A44"],
.jodit-color-picker__color-item[data-color="#666666"],
.jodit-color-picker__color-item[data-color="#B7B7B7"],
.jodit-color-picker__color-item[data-color="#9900FF"] {
  display: block;
}

.jodit-color-picker__color-item {
  height: 38px;
  width: 38px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
html {
  height: 100vh;
  background: #f8f9fa;
}

.position-box {
  position: absolute;
  background: #fff;
  z-index: 1;
  top: 40px;
  left: -113px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
}
