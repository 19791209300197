code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  background: #f6f5f1;
}

.stsearch-box input {
  border: none !important;
}

.btn-color {
  --bs-btn-color: #fff;
  --bs-btn-bg: #674bff;
  --bs-btn-border-color: #674bff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4628ea;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #674bff;
  --bs-btn-disabled-border-color: #674bff;
}

.pac-container {
  z-index: 1500 !important;
  /* Ensure this value is higher than the modal's z-index */
}

.pac-target-input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
  border: 1px solid #dee2e6;
}

.pac-target-input::placeholder {
  color: #A9A9A9;
}

.rc-pagination-item-active {
  background-color: #674bff !important;
  color: #fff !important;
  border-color: #674bff !important;
}